import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { headerDropdown, mainHeader } from "../../constants/header";
import "./Header.scss";
import { Dropdown, Tooltip } from "antd";
import { UserInfo } from "../../assets/svgs";
import { Menu } from "antd";
import { storage } from "services/config/storage";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import { fetchCMSLanguage } from "utils/helpers";
import BellICon from "../../assets/svgs/bellIcon.png";
import NCPLogo from "../../assets/images/ncp-logo.png";

const Header = ({ language }) => {
  const history = useHistory();
  const [activePath, setActivePath] = useState("");
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());

  const { SubMenu } = Menu;

  const permissions = storage.fetch.permissions();
  let header;

  const handleRouteChange = (path) => {
    history.push("/" + path);
    setActivePath(history.location.pathname.replace(/ /g, ""));
  };

  const handleDropdown = ({ key }) => {
    switch (key) {
      case headerDropdown.logout: {
        return handleLogout();
      }
      default: {
        return null;
      }
    }
  };

  const handleLanguage = ({ key }) => {
    storage.set.language(key);
    setLanguageId(key);
    language(key);
  };

  const handleLogout = () => {
    storage.destroy?.authToken();
    storage.destroy?.permissions();
    handleRouteChange("login");
  };

  const adminAccess = () => {
    header = mainHeader?.filter(
      (item) =>
        permissions?.includes(item?.title) || item?.title === "CadreMaster"
    );
    return header;
  };

  useEffect(() => {
    setActivePath(history.location.pathname.replace(/ /g, ""));
  }, [history.location.pathname]);

  const menu = (
    <Menu onClick={handleDropdown}>
      <Menu.Item key={headerDropdown.logout}>Log out</Menu.Item>
      <SubMenu onClick={handleLanguage} title="Language">
        <Menu.Item key={ENGLISH}>English</Menu.Item>
        <Menu.Item key={HINDI}>Hindi</Menu.Item>
        <Menu.Item key={MARATHI}>Marathi</Menu.Item>
      </SubMenu>
    </Menu>
  );

  const isActivePath = (path) => {
    const result = activePath.substring(1).split("/")[0] === path;
    return result;
  };

  return (
    <div className="header-component">
      <div>
        <div className="main-header flex items-center justify-between">
          <Link className="dashboard-logo" to="/tasksandrewards">
            <img src={NCPLogo} alt="ncp" />
          </Link>

          <div className="category-container flex justify-between">
            {adminAccess()?.map((item, index) => {
              let tab = (
                <div
                  onClick={() =>
                    !item.isComingSoon &&
                    handleRouteChange(
                      item.title.toLowerCase().replace(/ /g, "")
                    )
                  }
                  key={index}
                  className={`${
                    item.isComingSoon ? "cursor-default" : "cursor-pointer"
                  } category-holder flex items-center ${
                    !item.isComingSoon &&
                    isActivePath(item.title.toLowerCase().replace(/ /g, ""))
                      ? "active-category"
                      : " "
                  }`}
                >
                  {item.activeImage ? (
                    isActivePath(item.title.toLowerCase().replace(/ /g, "")) ? (
                      item.activeImage
                    ) : (
                      item.inactiveImage
                    )
                  ) : (
                    <span className="menu-icon">{item.icon}</span>
                  )}

                  <span className="category-name text-white semibold">
                    {item.name?.[languageId]}
                  </span>
                </div>
              );
              if (item.isComingSoon) {
                tab = (
                  <Tooltip title={"Coming soon.."} key={index}>
                    {tab}
                  </Tooltip>
                );
              }
              return tab;
            })}
          </div>

          <div className="flex items-center">
            <div className="cursor-pointer bell-icon">
              <img
                src={BellICon}
                style={{ marginRight: "3vw" }}
                alt="bell-icon"
              />
            </div>
            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <div className="logout cursor-pointer">
                <UserInfo />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
