import NCPLogo from "../../assets/images/ncp-logo.png";
import "./privacypolicy.scss";

export const Privacy = () => {
  return (
    <div className="privacy-container">
      <div className="header">
        <img
          style={{ marginRight: "10px", marginLeft: "20px" }}
          width={"50px"}
          height={"50px"}
          src={NCPLogo}
          alt="NCP"
        />
        <h3>NCP-SP</h3>
      </div>
      <h1>PRIVACY POLICY</h1>
      <p>
        These Terms and Conditions ("T&C's" or "Terms & Conditions") govern your
        access to and use of this website/(s) ("website" or "site") operated by
        Nationalist Congress Party-Sharadchandra Pawar (Please specify whether
        NCP-SP Maharashtra Pradesh or India). This statement discloses the
        privacy practices for the websites and mobile application of Nationalist
        Congress Party–Sharadchandra Pawar, Please specify whether NCP-SP
        Maharashtra Pradesh or India). having it's Registered Office at
        Thackersey House Mumbai Port Trust Building, 15, JN Heredia Rd, Ballard
        Estate, Fort, Mumbai, Maharashtra 400001.
      </p>
      <br />
      <p>
        As we update, improve and expand the Services, this policy may change,
        so please refer back to it periodically. Our products or services may
        include third-party products or services. When you use these products or
        services for which you may have consented, there is a likelihood that
        they may also collect and use your information to serve you. By
        accessing the website or this application or otherwise using the
        services, you consent to collection, storage, and use of the personal
        information you provide (including any changes thereto as provided by
        you) for any of the services that we offer. The Nationalist Congress
        Party-Sharadchandra Pawar respects the privacy of the users of the
        Services and is committed to reasonably protect it in all respects. This
        page informs you of our policies regarding the collection, use and
        disclosure of Personal Information we receive from users of the Site.
      </p>
      <p>
        We use your Personal Information only for providing and improving the
        Site. By using the Site you agree to the collection and use of
        information in accordance with this policy.
      </p>
      <p>
        Any use of the website/(s) owned and managed by Nationalist Congress
        Party-Sharadchandra Pawar, Please specify whether NCP-SP Maharashtra
        Pradesh or India)shall be construed as your acceptance of this Privacy
        Policy and if you do not accept the Privacy Policy enumerated herein
        below, please do not proceed further to use/access the site.
      </p>
      <p>
        What information do we collect? While using our Site, we may ask you to
        provide us with certain personally identifiable information that can be
        used to contact or identify you. Personally identifiable information may
        include, but is not limited to your name ("Personal Information")
      </p>
      <h2>A. INFORMATION SUPPLIED BY USERS</h2>
      <ul>
        <li>Registration data:</li>
        <p>
          When you register on the website, Application and for the Service, we
          ask that you provide basic contact Information such as your name,
          gender, age, address, pin code, contact number, occupation, interests
          and email address etc. When you register using your other accounts
          like on Facebook, Twitter, Gmail, Instagram etc. we shall retrieve
          Information from such account only with your permission to continue to
          interact with you and to continue providing the Services and not share
          that any further to any party external to users who can benefit from
          it directly or indirectly.
        </p>
        <li>Voluntary information:</li>
        <p>
          We may collect additional information at other times, including but
          not limited to, when you provide feedback, change your content or
          email preferences, respond to a survey, or communicate with us. If you
          apply to take part in a program on politics, for example, we might ask
          you about your political opinions. We do not share your personal
          private data with anyone. While using our site, we may ask you to
          provide us with certain personal identifiable information that can be
          used to contact or identify identifiable you. Personally information
          may include, but is not limited to your name (Personal Information)
          (The 3 marked lines are repeated but we should keep it because the
          earlier one is for what information do we collect? And the latter is
          for Information supplied by users.)
        </p>
      </ul>
      <h2>B. INFORMATION AUTOMATICALLY COLLECTED/ TRACKED WHILE NAVIGATION</h2>
      <ul>
        <li>Cookies/Local Storage:</li>
        <p>
          Cookies/Local Storage are small files that a site or its service
          provider transfers to your computers' hard drive through your Web
          browser (if you allow) that enables the sites or service providers
          systems to recognize your browser and capture and remember certain
          information. To improve the responsiveness of the "Product" for our
          users, we may use "cookies/local storage", or similar electronic tools
          to collect information to assign each visitor a unique, random number
          as a User Identification (User ID) to understand the user's individual
          interests using the identified computer. Unless you voluntarily
          identify yourself (through registration, for example), we will have no
          way of knowing who you are, even if we assign a cookie to your
          computer. The only personal information a cookie can contain is
          information you supply. A cookie cannot read data off your hard drive.
          Our advertisers may also assign their own cookies/local storage to
          your browser (if you click on their ads), a process that we do not
          control. We receive and store certain types of information whenever
          you interact with us via website, Application or Service though your
          computer/laptop/netbook or mobile/tablet/pad/handheld device etc. Your
          Freedom to Withdraw Your Consent at Any Time: If you do not wish to
          accept cookies/local storage in connection with your use of the
          Platform, you will need to delete, and block or disable cookies/local
          storage via your browser settings (Please consult your web browser's
          Help documentation for more information on this process.) Please note
          that disabling cookies/local storage will affect the functionality of
          the Platform, and may prevent you from being able to access certain
          features on the websites /products/applications.
        </p>
        <li>Opting out:</li>
        <p>
          If a user opts out using the Ads Settings, the unique DoubleClick
          cookie ID on the user's browser is overwritten with the phrase
          "OPT_OUT". Because there is no longer a unique cookie ID, the opt-out
          cookie can't be associated with a particular browser.
        </p>
        <li>Log File Information:</li>
        <p>
          We automatically collect limited information about your computer's
          connection to the Internet, mobile number, including your IP address,
          when you visit our site, application or service. Your IP address is a
          number that lets computers attached to the Internet know where to send
          you data such as the pages you view. We automatically receive and log
          information from your browser, including your IP address, your
          computer's name, your operating system, browser type and version, CPU
          speed, and connection speed. We may also collect log information from
          your device, including your location, IP address, your device's name,
          device's serial number or unique identification number (e.g.. UDID on
          your iOS device), your device operating system, browser type and
          version, CPU speed, and connection speed etc. We use cookies, log
          files, pixel tags, local storage objects, and other tracking
          automatically information technologies to collect about your
          activities, such as your searches, page views, date and time of your
          visit, and other information about your use of the Platform.
        </p>
        <li>Clear GIFs:</li>
        <p>
          We may use "clear GIFs" (Web Beacons) to track the online usage
          patterns of our users in an anonymous manner, without personally
          identifying the user. We may also use clear GIFs in HTML-based emails
          sent to our users to track which emails are opened by recipients. We
          use this information to inter-alia deliver our web pages to you upon
          request, to tailor our Site, Application or Service to the interests
          of our users, to measure traffic within our Application to improve the
          Application quality, functionality and interactivity and let
          advertisers know the geographic locations from where our visitors
          come.
        </p>
        <li>Information from other Sources:</li>
        <p>
          We may receive information about you from other sources, add it to our
          account information and treat it in accordance with this policy. If
          you provide information to the platform provider or other partner,
          whom we services, your information and provide account order
          information may be passed on to us. We may obtain updated contact
          information from third parties in order to correct our records and
          fulfill; the Services or to communicate with you. Information on your
          activities outside the platform when you talk about us like if you've
          mentioned us in a Twitter post,we’ll collect your Twitter handle.
        </p>
        <li>Demographic and purchase information:</li>
        <p>
          We may reference other sources of demographic and other information in
          order to provide you with more communications promotions targeted and
        </p>
        <li>Links To Third Party Sites:</li>
        <p>
          Occasionally, at our discretion, we may include or offer third party
          products or services on our website. We do not provide any personally
          identifiable information to third party websites without your consent.
          The Application may include links to other websites or applications.
          Such websites or applications are governed by their respective privacy
          policies, which are beyond our control. Once you leave our servers
          (you can tell where you are by checking the URL in the location bar on
          your browser), use of any information you provide is governed by the
          privacy policy of the operator of the application, you are visiting.
          That policy may differ from ours. If you can't find the privacy policy
          of any of these sites via a link from the application's homepage, you
          should contact the application owners directly for more information.
        </p>
      </ul>
      <h2>C. INFORMATION USE BY THE COMPANY</h2>
      <p>
        We do not share your personal information with unknown parties. Any of
        the information we collect from you may be used in one of the following
        ways:
      </p>
      <p>
        a) To personalize your experience (your information helps us to better
        respond to your individual needs)
      </p>
      <p>
        b) To improve our website (we continually strive to improve our website
        offerings based on the information and feedback we receive from you)
      </p>
      <p>c) To administer a contest, promotion, survey or other site feature</p>
      <p>d) To send periodic emails</p>
      <p>
        e) To measure traffic within our site and let us know the geographic
        locations from where our visitors come.
      </p>
      <br />
      <p>
        The email address you provide may be used to send you information,
        respond to inquiries, and/or other requests or questions.
      </p>
      <br />
      <p>
        Any personally identifiable information provided by you will not be
        considered as sensitive if it is freely available and / or accessible in
        the public domain like any comments, messages, blogs, scribbles
        available on social platforms like Facebook, Twitter, Instagram etc. Any
        posted/uploaded/ conveyed/communicated by users on the public sections
        of the "Application" becomes published content and is not considered
        personally identifiable information subject to this Privacy Policy. In
        case you choose to decline to submit personally identifiable information
        on the Application, we may not be able to provide certain services on
        the Application to you. We will make reasonable efforts to notify you of
        the same at the time of opening your account. In any case, we will not
        be liable and or responsible for the denial of certain services to you
        for lack of you providing the necessary personal information. When you
        register with the Application or Services, we contact you from time to
        time about updation of your personal information to provide the users
        such features that we believe may benefit / interest you.
      </p>
      <h2>D. INFORMATION SHARING</h2>
      <p>
        a) When it is requested or required by law or by any court or
        governmental agency or authority to disclose, for the purpose of
        verification of identity, or for the prevention, detection,
        investigation including cyber incidents, or for prosecution and
        punishment of offences. These disclosures are made in good faith and
        belief that such disclosure is reasonably necessary for enforcing these
        Terms or for complying with the applicable laws and regulations.
      </p>
      <p>
        b) Nationalist Congress Party proposes to share such information within
        the party for the purpose of processing personal information on its
        behalf. We also ensure that these recipients of such information agree
        to process such information based on our instructions and in compliance
        with this Privacy Policy and any other appropriate confidentiality and
        security measures.
      </p>
      <p>
        c) Nationalist Congress Party-Sharadchandra Pawar may present
        information within the party- to help understand our audience on our
        websites or Applications however it is usually in the form of aggregated
        statistics on traffic to various pages within our site.
      </p>
      <p>
        d) Anonymized and Aggregated Data. We may share aggregated or de-
        identified information within the party for research, analytics and
        other purposes, provided such information does not identify a particular
        individual.
      </p>
      <h2>E. HOW DO WE PROTECT YOUR INFORMATION / Security Policy</h2>
      <p>
        We take appropriate security measures to protect unauthorized access
        against to or unauthorized alteration, disclosure or destruction of
        data. These include internal reviews of our data collection, storage and
        processing practices and security measures, including appropriate
        encryption and physical security measures to guard against unauthorized
        access to systems where we store personal data. All information gathered
        on TIL is securely stored within the Company controlled database. The
        database is stored on servers secured behind a firewall; access to the
        servers is password-protected and is strictly limited. However, as
        effective as our security measures are, no security system is
        impenetrable. The security of your Personal Information is of paramount
        importance to us, but remember that no method of transmission over the
        internet, or method of electronic storage is 100% secure, while we
        strive to use commercially acceptable means to protect your Personal
        Information, We cannot guarantee the absolute security of our database,
        nor can we guarantee that information you supply will not be intercepted
        while being transmitted to us over the Internet. And, of course, any
        information you include in a posting to the discussion areas is
        available to anyone with Internet access.
      </p>
      <h2>F. ACCESSING & UPDATING PERSONAL INFORMATION</h2>
      <p>
        When you use the Services Site (or any of its sub sites), we make good
        faith efforts to provide you, as and when requested by you, with access
        to your personal information and shall further ensure that any personal
        information or sensitive personal data or information found to be
        inaccurate or deficient shall be corrected or amended as feasible,
        subject to any requirement for such personal information or sensitive
        personal data or information to be retained by law or for legitimate
        business purposes. We ask individual users to identify themselves and
        the information requested to be accessed, corrected or removed before
        processing such requests, and we may decline to process requests that
        are unreasonably repetitive or systematic, require disproportionate
        technical effort, jeopardize the privacy of others, or would be
        extremely impractical (for instance, requests concerning information
        residing on backup tapes), or for which access is not otherwise
        required. In any case, where we provide information access and
        correction, we perform this service free of charge, except if doing so
        would require a disproportionate effort. Because of the way we maintain
        certain services, after you delete your information, residual copies may
        take a period of time before they are deleted from our active servers
        and may remain in our backup systems.
      </p>
      <h2>G. DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES?</h2>
      <p>
        This online privacy policy applies only to information collected through
        our website and application and not to information collected offline.
      </p>
      <h2>H. YOUR CONSENT</h2>
      <p>
        By using our site, you consent to our websites and application privacy
        policy. ( Please specify the Minimum Age Requirement to use the App)
      </p>
      <h2>I. CHANGES TO OUR PRIVACY POLICY</h2>
      <p>
        The internet is an ever evolving medium. We may alter our privacy policy
        from time to time to incorporate necessary changes in technology,
        applicable law or any other variant. In any case, we reserve the right
        to change (at any point of time) the terms of this Privacy Policy or the
        Terms of Use. Any changes we make will be effective immediately on
        notice, which we may give by posting the new policy on the
        "Application". Your use of the Application or Services after such notice
        will be deemed acceptance of such changes. We may also make reasonable
        efforts to inform you via electronic mail. In any case, you are advised
        to review this Privacy Policy periodically on the "Application") to
        ensure that you are aware of the latest version.
      </p>
      <h2>J. CONTACTING US</h2>
      <p>
        We may use your Personal Information to contact you with Newsletters,
        Marketing or Promotional Materials and other Valuable information.
      </p>
      <p>
        If there are any questions regarding this privacy policy you may contact
        us at: Nationalist Congress Party - Sharadchandra Pawar, located at
        Thackersey House Mumbai Port Trust Building, 15, JN Heredia Rd, Ballard
        Estate, Fort, Maharashtra 400001. Mumbai,
      </p>
      <p>E-mail :</p>
      <p>
        We request you to please provide the following information in your
        complaint: -
      </p>
      <ul>
        <li>Identification of the information provided by you;</li>
        <li>
          Clear statement as to whether the information is personal information
          or sensitive personal information;
        </li>
        <li>Your address, telephone number or e-mail address;</li>
        <li>
          A statement that you have a good-faith belief that the information has
          been processed incorrectly or disclosed without authorization, as the
          case may be;
        </li>
        <li>
          A statement, under penalty of perjury, that the information in the
          notice is accurate, and that the information being complained about
          belongs to you;
        </li>
        <li>
          You may also contact us in case you have any questions / suggestions
          about the Policy using the contact information mentioned above.
        </li>
      </ul>
      <p>
        Nationalist Congress Party-Sharadchandra Pawar may reach out to you to
        confirm or discuss certain details about your complaint and issues
        raised.
      </p>
      <p>
        Nationalist Congress Party-Sharadchandra Pawar shall not be responsible
        for any communication, if addressed, to any non-designated person in
        this regard.
      </p>
      <h2>APPLICABLE LAWS</h2>
      <p>
        The Privacy and Data Protection is mandated Under Section 43A of The
        Information Technology Act, 2000 read with Information Technology
        (Reasonable Security Practices And Procedures And Sensitive Personal
        Data or Information) Rules, 2011.
      </p>
      <h2>App Permissions</h2>
      <ul>
        <li>
          <h2>LOCATION</h2>
          <p>
            Some features and functionality in our Apps would require you to
            provide your location. When we have location information, we use it
            to tailor our Services for you, like helping you to check-in and
            find local events hosted in your locality or the events happening
            nearby regions which were hosted by the NCP(Nationalist congress
            Party). When you use Google Services we may collect and process
            information about your actual location We use Integrated third party
            APIS to determine location including IP address, GPS, and other
            sensors that may for example provide Google with Information on
            nearby devices, WiFi access points and cell towers
          </p>
          <p>
            Some features and functionality in our Apps would require you to
            provide your location. When we have location information, we use it
            to tailor our Services for you, like helping you to check-in and
            find local events hosted in your locality or the events happening
            nearby regions which were hosted by the NCP-SP (Nationalist congress
            Party-SP). When you use Google Services we may collect and process
            information about your actual location We use Integrated third party
            APIS to determine location including IP address, GPS, and other
            sensors that may for example provide Google with Information on
            nearby devices, WiFi access points and cell towers If you opt in to
            sharing your location with us, whenever you use our Apps on your
            mobile/ smart device, we collect and use your geocoordinates (e.g.
            latitude and longitude) to tailor the services to your current
            location. If you have location services turned on, We will only
            process your location with your express permission. Your location is
            never shared with others. You may remove your permission at any time
            by going into your operating device settings and toggling off
            Location Permission. If the User has not opted in or is not willing
            to share the location information we would show the nearby events
            hosted by the NCP-SP (Nationalist congress Party-Sharadchandra
            Pawar) by considering the Mumbai, India location as a host place
            with message a Notification mentioning that Location access has been
            denied, this function is implemented for better user experiences. We
            are not sharing any of the user information data provided by the
            user to any Third party, we are using it to analyse our services to
            improve the user experience and to provide better usability to our
            app user.
          </p>
        </li>
        <li>
          <h2>STORAGE</h2>
          <p>
            Storage permissions are taken for accessing the shared external
            storage which is mounted in the devices which has access to Full
            read and write cases to any location of the volume specified. When
            an app is granted storage permission, it can access the device
            storage, when the app is in use in any mobile/ smart devices to
            upload the Photos, Videos in the app by the User or to save the
            media files in the form of Images, Files in external storage as raw
            form or in terms of caches for better User experience in the
            app-specific directory. This action will be performed only when the
            external storage is mounted and the permissions are granted. You may
            remove your permission at any time by going into your operating
            device settings and toggling off Storage Permission.
          </p>
        </li>
      </ul>
    </div>
  );
};

