/* eslint-disable no-useless-escape */
import { avoidCache } from "constants/dummyData";
import moment from "moment";
import * as Yup from "yup";

export const TIMELINE_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
  }),
  imagesMediaId: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { imagesMediaId } = this.parent;
        for (let i = 0; i < imagesMediaId?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = imagesMediaId[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  year: Yup.string().required("Year is required"),
  date: Yup.date().typeError("Date is required").required("Date is required"),
  urlLink: Yup.string()
    .matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
      "Enter a valid URL"
    )
    .required("URL is required"),
});

export const MAGAZINE_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
  }),
  imagesMediaId: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { imagesMediaId } = this.parent;
        for (let i = 0; i < imagesMediaId?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = imagesMediaId[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  year: Yup.string().required("Year is required"),
  publishedDate: Yup.date()
    .typeError("Date is required")
    .required("Date is required"),
  pdf: Yup.string().nullable().required("PDF is required"),
  isNotification: false,
});

export const MANIFESTO_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      description: Yup.string()
        .test(
          "description",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Description is required"),
    }),
    hi: Yup.object().shape({
      description: Yup.string()
        .test(
          "description",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Description is required"),
    }),
    mr: Yup.object().shape({
      description: Yup.string()
        .test(
          "description",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Description is required"),
    }),
  }),
  time: Yup.date().typeError("Time is required").required("Time is required"),
  date: Yup.date().typeError("Date is required").required("Date is required"),
});

export const SCHEMES_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      department: Yup.string().required("Department is required"),
      schemeDetails: Yup.string()
        .test(
          "schemeDetails",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Scheme details is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      department: Yup.string().required("Department is required"),
      schemeDetails: Yup.string()
        .test(
          "schemeDetails",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Scheme details is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      department: Yup.string().required("Department is required"),
      schemeDetails: Yup.string()
        .test(
          "schemeDetails",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Scheme details is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
  }),
  mediaId: Yup.string().required("Image is required"),
  dateAdded: Yup.date()
    .typeError("Date is required")
    .required("Date is required"),
});

export const ORGANIZATION_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      president: Yup.string().required("Name is required"),
      description: Yup.string()
        .test(
          "description",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Description is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      president: Yup.string().required("Name is required"),
      description: Yup.string()
        .test(
          "description",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Description is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      president: Yup.string().required("Name is required"),
      description: Yup.string()
        .test(
          "description",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Description is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
  }),
  order: Yup.string()
    .required("Order is required")
    .test("Order", "Please entaer a valid integer", function (value) {
      const { order } = this.parent;
      const str = order?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+order)) {
        return false;
      } else {
        return true;
      }
    }),
  imagesMediaId: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { imagesMediaId } = this.parent;
        for (let i = 0; i < imagesMediaId?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = imagesMediaId[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  activities: Yup.array()
    .of(
      Yup.object().shape({
        mediaId: Yup.string().required("Image is required"),
        url: Yup.string()
          .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
            "Enter a valid url!"
          )
          .required("URL is required"),
        data: Yup.object().shape({
          en: Yup.object().shape({
            title: Yup.string().required("Title is required"),
          }),
          hi: Yup.object().shape({
            title: Yup.string().required("Title is required"),
          }),
          mr: Yup.object().shape({
            title: Yup.string().required("Title is required"),
          }),
        }),
      })
    )
    .required("required"),
});

export const LEADERS_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Leader name is required"),
      publicRepresentative: Yup.string().required(
        "Public Representative is required"
      ),
      partyPosition: Yup.string().required("Position is required"),
      constituency: Yup.string().required("Constituency is required"),
      successStorySummary: Yup.string()
        .test(
          "successStorySummary",
          "Character length must be less then 20,000",
          (val) => {
            if (val?.length && val?.length > 20000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Summary is required"),
      about: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Leader name is required"),
      publicRepresentative: Yup.string().required(
        "Public Representative is required"
      ),
      partyPosition: Yup.string().required("Position is required"),
      constituency: Yup.string().required("Constituency is required"),
      successStorySummary: Yup.string()
        .test(
          "successStorySummary",
          "Character length must be less then 20,000",
          (val) => {
            if (val?.length && val?.length > 20000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Summary is required"),
      about: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Leader name is required"),
      publicRepresentative: Yup.string().required(
        "Public Representative is required"
      ),
      partyPosition: Yup.string().required("Position is required"),
      constituency: Yup.string().required("Constituency is required"),
      successStorySummary: Yup.string()
        .test(
          "successStorySummary",
          "Character length must be less then 20,000",
          (val) => {
            if (val?.length && val?.length > 20000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Summary is required"),
      about: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
  }),
  order: Yup.string()
    .required("Order is required")
    .test("Order", "Please entaer a valid integer", function (value) {
      const { order } = this.parent;
      const str = order?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+order)) {
        return false;
      } else {
        return true;
      }
    }),
  experience: Yup.string()
    .required("Experience is required")
    .test("Experience", "Please entaer a valid integer", function (value) {
      const { experience } = this.parent;
      const str = experience?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+experience)) {
        return false;
      } else {
        return true;
      }
    }),
  wins: Yup.string()
    .required("Wins is required")
    .test("wins", "Please enter a valid integer", function (value) {
      const { wins } = this.parent;
      const str = wins?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+wins)) {
        return false;
      } else {
        return true;
      }
    }),
  successStoryImage: Yup.string().required("Image is required"),
  imagesMediaId: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { imagesMediaId } = this.parent;
        for (let i = 0; i < imagesMediaId?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = imagesMediaId[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  videos: Yup.array()
    .of(
      Yup.object().shape({
        mediaId: Yup.string().required("Image is required"),
        thumbnailId: Yup.string().required("Image is required"),
        date: Yup.date()
          .typeError("Date is required")
          .required("Date is required"),
        data: Yup.object().shape({
          en: Yup.object().shape({
            location: Yup.string().required("Location is required"),
            section: Yup.string().required("Section is required"),
            title: Yup.string().required("Title is required"),
          }),
          hi: Yup.object().shape({
            location: Yup.string().required("Location is required"),
            section: Yup.string().required("Section is required"),
            title: Yup.string().required("Title is required"),
          }),
          mr: Yup.object().shape({
            location: Yup.string().required("Location is required"),
            section: Yup.string().required("Section is required"),
            title: Yup.string().required("Title is required"),
          }),
        }),
      })
    )
    .required("Fields are required"),
  achievements: Yup.array()
    .of(
      Yup.object().shape({
        mediaId: Yup.string().required("Image is required"),
        awardDate: Yup.date()
          .typeError("Date is required")
          .required("Date is required"),
        data: Yup.object().shape({
          en: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            authority: Yup.string().required("Authority name is required"),
          }),
          hi: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            authority: Yup.string().required("Authority name is required"),
          }),
          mr: Yup.object().shape({
            name: Yup.string().required("Name is required"),
            authority: Yup.string().required("Authority name is required"),
          }),
        }),
      })
    )
    .required("Fields are required"),
});

export const PARTY_BIO_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      heading: Yup.string().required("Party heading is required"),
      bio1: Yup.string()
        .test("bio1", "Character length must be less then 5000", (val) => {
          if (val?.length && val?.length > 5000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Party bio is required"),
      bio2: Yup.string()
        .test("bio2", "Character length must be less then 5000", (val) => {
          if (val?.length && val?.length > 1000000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Party bio is required"),
    }),
    hi: Yup.object().shape({
      heading: Yup.string().required("Party heading is required"),
      bio1: Yup.string()
        .test("bio1", "Character length must be less then 5000", (val) => {
          if (val?.length && val?.length > 5000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Party bio is required"),
      bio2: Yup.string()
        .test("bio2", "Character length must be less then 5000", (val) => {
          if (val?.length && val?.length > 5000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Party bio is required"),
    }),
    mr: Yup.object().shape({
      heading: Yup.string().required("Party heading is required"),
      bio1: Yup.string()
        .test("bio1", "Character length must be less then 5000", (val) => {
          if (val?.length && val?.length > 5000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Party bio is required"),
      bio2: Yup.string()
        .test("bio2", "Character length must be less then 5000", (val) => {
          if (val?.length && val?.length > 5000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Party bio is required"),
    }),
  }),
  imagesMediaId: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { imagesMediaId } = this.parent;
        for (let i = 0; i < imagesMediaId?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = imagesMediaId[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  videos: Yup.array().of(
    Yup.object().shape({
      mediaId: Yup.string().required("Image is required"),
      thumbnailId: Yup.string().required("Image is required"),
      date: Yup.date()
        .typeError("Date is required")
        .required("Date is required"),
      data: Yup.object().shape({
        en: Yup.object().shape({
          location: Yup.string().required("Location is required"),
          title: Yup.string().required("Title is required"),
        }),
        hi: Yup.object().shape({
          location: Yup.string().required("Location is required"),
          title: Yup.string().required("Title is required"),
        }),
        mr: Yup.object().shape({
          location: Yup.string().required("Location is required"),
          title: Yup.string().required("Title is required"),
        }),
      }),
    })
  ),
  activities: Yup.array().of(
    Yup.object().shape({
      mediaId: Yup.string().required("Image is required"),
      url: Yup.string()
        .matches(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
          "Enter a valid url!"
        )
        .required("Web url is required"),
      data: Yup.object().shape({
        en: Yup.object().shape({
          title: Yup.string().required("Title is required"),
        }),
        hi: Yup.object().shape({
          title: Yup.string().required("Title is required"),
        }),
        mr: Yup.object().shape({
          title: Yup.string().required("Title is required"),
        }),
      }),
    })
  ),
});

export const ORGANIZATIONALSTRUCTURE_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      description: Yup.string(),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      description: Yup.string(),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      description: Yup.string(),
    }),
  }),
  order: Yup.string()
    .required("Order is required")
    .test("Order", "Please entaer a valid integer", function (value) {
      const { order } = this.parent;
      const str = order?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+order)) {
        return false;
      } else {
        return true;
      }
    }),
});

export const MLA__MLC_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      description: Yup.string(),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      description: Yup.string(),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      description: Yup.string(),
    }),
  }),
  order: Yup.string()
    .required("Order is required")
    .test("Order", "Please entaer a valid integer", function (value) {
      const { order } = this.parent;
      const str = order?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+order)) {
        return false;
      } else {
        return true;
      }
    }),
  representativeType: Yup.string().required("Representative type is required"),
});

export const MEMBERS_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Leader name is required"),
      partyPosition: Yup.string().required("Position is required"),
      organization: Yup.string().required("Organization is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Leader name is required"),
      partyPosition: Yup.string().required("Position is required"),
      organization: Yup.string().required("Organization is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Leader name is required"),
      partyPosition: Yup.string().required("Position is required"),
      organization: Yup.string().required("Organization is required"),
    }),
  }),
  phoneNumbers: Yup.array().of(
    Yup.string()
      .test("phoneNumbers", "Enter a valid number", (value) => {
        var format = /[`!@#$%^&*_\=\[\]{};':"\\|,.<>\/?~]/;
        var letters = /[a-zA-Z]/g;
        let isValid = true;
        let addCount = 0;
        for (let i = 0; i < value?.length; i++) {
          if (format.test(value?.[i])) isValid = false;
          if (value[i] === "+") addCount++;
          if (addCount > 1) isValid = false;
          if (letters.test(value?.[i])) isValid = false;
        }
        return isValid;
      })
      .required("Contact number is required")
  ),
  order: Yup.string()
    .required("Order is required")
    .test("Order", "Please entaer a valid integer", function (value) {
      const { order } = this.parent;
      const str = order?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+order)) {
        return false;
      } else {
        return true;
      }
    }),
});

export const getLeaderInitialValues = async (values = {}) => ({
  id: values.id,
  data: values.data || {
    en: {
      name: "",
      about: "",
      successStorySummary: "",
      partyPosition: "",
      publicRepresentative: undefined,
      constituency: "",
    },
    hi: {
      name: "",
      about: "",
      successStorySummary: "",
      partyPosition: "",
      publicRepresentative: undefined,
      constituency: "",
    },
    mr: {
      name: "",
      about: "",
      successStorySummary: "",
      partyPosition: "",
      publicRepresentative: undefined,
      constituency: "",
    },
  },
  imageList: await populateImagesList(values?.images),
  imagesMediaId: populateMediaId(values?.images),
  order: values?.order || "",
  experience: values?.experience || "",
  wins: values?.wins || "",
  successStoryImageLink: values.successStoryImageLink
    ? values.successStoryImageLink + avoidCache()
    : "",
  successStoryImage: values.successStoryImage || "",
  videos:
    values?.videos?.map((item) => {
      return {
        ...item,
        thumbnaillink: item?.thumbnaillink + avoidCache(),
      };
    }) || [],
  achievements:
    values?.achievements?.map((item) => {
      return {
        ...item,
        link: item.link + avoidCache(),
      };
    }) || [],
  isActive: values?.isActive || true,
});

export const achievementsSchema = {
  mediaId: "",
  link: "",
  awardDate: undefined,
  data: {
    en: {
      name: "",
      authority: "",
    },
    hi: {
      name: "",
      authority: "",
    },
    mr: {
      name: "",
      authority: "",
    },
  },
};

export const leadersVideosSchema = {
  mediaId: "",
  thumbnailId: "",
  date: undefined,
  data: {
    en: {
      title: "",
      section: undefined,
      location: undefined,
    },
    hi: {
      title: "",
      section: undefined,
      location: undefined,
    },
    mr: {
      title: "",
      section: undefined,
      location: undefined,
    },
  },
};

export const getAboutPartyInitialValues = async (values = {}) => ({
  data: values.data || {
    en: {
      bio1: "",
      bio2: "",
      heading: "",
    },
    hi: {
      bio1: "",
      bio2: "",
      heading: "",
    },
    mr: {
      bio1: "",
      bio2: "",
      heading: "",
    },
  },
  id: values.id,
  imageList: await populateImagesList(values?.images),
  imagesMediaId: populateMediaId(values?.images),
  videos: values?.videos?.map((item) => {
    return {
      ...item,
      thumbnaillink: item?.thumbnaillink + avoidCache(),
    };
  }) || [leadersVideosSchema],
  activities: values?.activities?.map((item) => {
    return {
      ...item,
      link: item?.media?.link + avoidCache(),
      media: { ...item?.media, link: item?.media?.link + avoidCache() },
    };
  }) || [activitiesSchema],
});

export const activitiesSchema = {
  mediaId: "",
  url: "",
  data: {
    en: {
      title: "",
    },
    hi: {
      title: "",
    },
    mr: {
      title: "",
    },
  },
};

export const getTimelineInitialValues = async (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      shortSummary: "",
    },
    hi: {
      title: "",
      shortSummary: "",
    },
    mr: {
      title: "",
      shortSummary: "",
    },
  },
  imageList: await populateImagesList(values?.coverImageId),
  imagesMediaId: populateMediaId(values?.coverImageId),
  year: values.year || undefined,
  date: values.date || undefined,
  urlLink: values.urlLink || "",
  isActive: values?.isActive || true,
});

export const getMagazineInitialValues = async (values = {}) => {
  return {
    data: values.data || {
      en: {
        title: "",
      },
      hi: {
        title: "",
      },
      mr: {
        title: "",
      },
    },
    imageList: await populateMagazineImagesList(values?.contentMedia),
    imagesMediaId: populateMagazineImagesMediaId(values?.contentMedia),
    year: values?.year || undefined,
    publishedDate: values?.publishedDate || undefined,
    isActive: values?.isActive || true,
    pdf: values?.contentMedia?.find((item) => item.media?.type === "pdf")?.media
      ?.link,
  };
};

export const getManifestoInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      description: "",
    },
    hi: {
      description: "",
    },
    mr: {
      description: "",
    },
  },
  date: values.date || undefined,
  time: moment(values.time, "HH:mm:ss").toISOString() || undefined,
  isActive: values?.isActive || true,
});

export const getSchemesInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      department: "",
      schemeDetails: "",
      shortSummary: "",
    },
    hi: {
      title: "",
      department: "",
      schemeDetails: "",
      shortSummary: "",
    },
    mr: {
      title: "",
      department: "",
      schemeDetails: "",
      shortSummary: "",
    },
  },
  mediaId: values.mediaId || "",
  media: values.media
    ? { ...values.media, link: values.media?.link + avoidCache() }
    : {},
  dateAdded: values.dateAdded || undefined,
  isActive: values?.isActive || true,
});

export const getOrganizationInitialValues = async (values = {}) => ({
  data: values.data || {
    en: {
      president: "",
      description: "",
      name: "",
      shortSummary: "",
    },
    hi: {
      president: "",
      description: "",
      name: "",
      shortSummary: "",
    },
    mr: {
      president: "",
      description: "",
      name: "",
      shortSummary: "",
    },
  },
  imageList: await populateImagesList(values?.images),
  imagesMediaId: populateMediaId(values?.images),
  order: values?.order || "",
  activities: values.activities?.map((item) => {
    return {
      ...item,
      link: item.link,
    };
  }) || [activitiesSchema],
  isActive: values?.isActive || true,
});

export const getOrganizationalStructureInitialValues = async (values = {}) => ({
  data: values.data || {
    en: {
      description: "",
      name: "",
    },
    hi: {
      description: "",
      name: "",
    },
    mr: {
      description: "",
      name: "",
    },
  },
  representativeType: values.representativeType || undefined,
  order: values.order || "",
});

export const getMembersInitialValues = async (values = {}) => ({
  id: values?.id || "",
  data: {
    en: {
      organization: "",
      name: "",
      partyPosition: "",
    },
    hi: {
      organization: "",
      name: "",
      partyPosition: "",
    },
    mr: {
      organization: "",
      name: "",
      partyPosition: "",
    },
    ...values?.data,
  },
  organisationalStructureId: values?.organisationalStructureId || "",
  order: values.order || "",
  phoneNumbers: values.phoneNumbers || [""],
});

export const getMlAMLCMembers = async (values = {}) => ({
  id: values?.id || "",
  data: {
    en: {
      organization: "",
      name: "",
      partyPosition: "",
    },
    hi: {
      organization: "",
      name: "",
      partyPosition: "",
    },
    mr: {
      organization: "",
      name: "",
      partyPosition: "",
    },
    ...values?.data,
  },
  mlaNMlcId: values?.mlaNMlcId || "",
  order: values?.order || "",
  phoneNumbers: values.phoneNumber || [""],
});

const populateMagazineImagesList = async (imagesList = []) => {
  // let remainingSpace = 5 - imagesList?.length; //TOTAL MAX IMAGES ARE 5
  let arr = [];
  arr = imagesList?.reduce((final, current) => {
    if (current.media?.type === "image" && current.media.link) {
      return [...final, current.media.link];
    }
    return final;
  }, []);
  // const data = await Promise.all(arr);
  let i = 1;
  let remainingSpace = 5 - arr?.length;
  while (i <= remainingSpace) {
    arr.push("");
    i++;
  }
  return arr;
};

const populateMagazineImagesMediaId = (mediaId = []) => {
  let arr = [];
  arr = mediaId?.reduce((final, current) => {
    if (current.media?.type === "image" && current.media.id) {
      return [...final, current.media.id];
    }
    return final;
  }, []);
  let i = 1;
  let remainingSpace = 5 - arr?.length;
  while (i <= remainingSpace) {
    arr.push("");
    i++;
  }
  return arr;
};

const populateImagesList = async (imagesList = []) => {
  let remainingSpace = 5 - imagesList?.length; //TOTAL MAX IMAGES ARE 5
  let arr = [];
  arr = imagesList?.map((item) => item.link);
  // const data = await Promise.all(arr);
  let i = 1;
  while (i <= remainingSpace) {
    arr.push("");
    i++;
  }
  return arr;
};

const populateMediaId = (mediaId = []) => {
  let remainingSpace = 5 - mediaId?.length; //TOTAL MAX IMAGES ARE 5
  let arr = [];
  arr = mediaId?.map((item) => item.mediaId);
  let i = 1;
  while (i <= remainingSpace) {
    arr.push("");
    i++;
  }
  return arr;
};

