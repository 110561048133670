import { Editor } from "@tinymce/tinymce-react";
import { EditorIcons } from "assets/svgs";
import React, { useRef } from "react";
import "./index.scss";

export const RichTextEditor = ({
  onChangeEditor,
  characterLimit,
  LimitEditorCount,
  getTextEditorCurrentValue,
  editorValue,
  ...fieldProps
}) => {
  const editorRef = useRef();
  const charRef = useRef(undefined);

  const getCharCount = () => {
    return characterLimit - editorValue?.length || 0;
  };

  return (
    <>
      <Editor
        {...fieldProps}
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={(e) => {
          charRef.current = onChangeEditor(e);
        }}
        initialValue={getTextEditorCurrentValue}
        init={{
          height: 334,
          width: 460,
          plugins: [
            "print my-example-plugin preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
          ],
          menubar: false,
          contextmenu: "image",
          content_css: "/index.scss",
          verify_html: false,

          setup: function (editor) {
            editor.ui.registry.addIcon("underline", EditorIcons.underline);
            editor.ui.registry.addIcon("bold", EditorIcons.bold);
            editor.ui.registry.addIcon("forecolor", EditorIcons.textColor);
            editor.ui.registry.addIcon("italic", EditorIcons.italic);
            editor.ui.registry.addIcon("strikethrough", EditorIcons.italic);
          },

          placeholder: fieldProps.placeholder,
          statusbar: false,
          link_title: false,
          target_list: false,
          paste_webkit_styles: "all",
          default_link_target: "_blank",
          remove_linebreaks: false,
          // apply_source_formatting: true,
          link_context_toolbar: true,
          paste_retain_style_properties: "all",
          table_column_resizing: "resizetable",
          imagetools_cors_hosts: ["amazonaws.com"],

          toolbar:
            "bold italic underline fontsizeselect strikethrough link | backcolor forecolor | alignleft aligncenter alignright alignjustify",
          fontsize_formats: FONT_SIZES,
          content_style: `
          .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
            opacity: 0.3;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #131415 !important;
          }
          body { font-family: Helvetica, Arial, sans-serif; font-size: 16px } p { margin-bottom: 0; margin-top: 0; color: grey},
        `,
        }}
      />
      <p className="editor-char-limit">{getCharCount()} letters</p>
    </>
  );
};
const FONT_SIZES = new Array(100)
  .fill("")
  .map((_, index) => index >= 8 && `${index}px`)
  ?.filter((item) => item)
  ?.join(" ");
