import { imagesUrl } from "./imagesUrl";
import {
  TasksandRewardsIcon,
  ContentIcon,
  DashboardIcon,
  UserIcon,
  RolesIcon,
  PartyIcon,
  MasterIcon,
} from "../assets/svgs";
import ActiveParty from "../assets/images/partyActive.png";
import InActiveParty from "../assets/images/partyInActive.png";

export const mainHeader = [
  {
    title: "Dashboard",
    name: {
      en: "Dashboard",
      hi: "डैशबोर्ड",
      mr: "डॅशबोर्ड",
    },
    icon: <DashboardIcon />,
    isComingSoon: true,
  },
  {
    title: "Tasks & Rewards",
    icon: <TasksandRewardsIcon />,
    name: {
      en: "Tasks & Rewards",
      hi: "कार्य और पुरस्कार",
      mr: "कार्ये आणि पुरस्कार",
    },
  },
  {
    title: "Content",
    icon: <ContentIcon />,
    name: {
      en: "Content",
      hi: "विषय",
      mr: "सामग्री",
    },
  },
  {
    title: "Users",
    icon: <UserIcon />,
    name: {
      en: "Users",
      hi: "उपयोगकर्ताओं",
      mr: "वापरकर्ते",
    },
  },
  {
    title: "Roles",
    icon: <RolesIcon />,
    name: {
      en: "Roles",
      hi: "भूमिकाएँ",
      mr: "भूमिका",
    },
  },
  {
    title: "Merchandise",
    icon: <RolesIcon />,
    name: {
      en: "Merchandise",
      hi: "व्यापार",
      mr: "माल",
    },
  },
  {
    title: "Party",
    activeImage: <img src={ActiveParty} alt="active" />,
    inactiveImage: <img src={InActiveParty} alt="active" />,
    name: {
      en: "Party",
      hi: "दल",
      mr: "पार्टी",
    },
  },
  {
    title: "Master",
    icon: <MasterIcon />,
    name: {
      en: "Master",
      hi: "गुरुजी",
      mr: "मास्टर",
    },
  },
  {
    title: "Cadre",
    icon: <MasterIcon />,
    name: {
      en: "Cadre",
      hi: "काडर",
      mr: "संवर्ग",
    },
  },
  {
    title: "CadreMaster",
    icon: <MasterIcon />,
    name: {
      en: "Cadre Master",
      hi: "काडर गुरुजी",
      mr: "संवर्ग मास्टर",
    },
  },
];

export const headerDropdown = {
  logout: "logout",
};

export const innerHeader = [
  {
    name: "Pending",
    path: "pending",
    icon: "",
  },
  {
    name: "Completed",
    path: "completed",
    icon: imagesUrl.icons.checkCircleBlue,
  },
];
